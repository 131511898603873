import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./style/global.scss";
// import * as echarts from "echarts";
import echarts from "./echarts";
import "./icon.js";
import dayjs from "dayjs";
import axios from "axios";

Vue.use(ElementUI);

Vue.config.productionTip = false;

Vue.prototype.$echarts = echarts;
Vue.prototype.$dayJs = dayjs;
Vue.prototype.$axios = axios;

// 创建全局自定义指令-用于避免一定时间内多次点击
Vue.directive("debounce", {
  inserted: function (el, binding) {
    let timer: any = null;
    el.addEventListener("click", () => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        binding.value();
      }, 1000);
    });
  },
});

// 注册一个全局自定义指令 给el-input添加自动聚焦 v-focus
Vue.directive("focus", {
  // 当绑定元素插入到 DOM 中。
  inserted: function (el) {
    const inputEl = el.querySelector("input");
    if (inputEl) {
      // 聚焦元素
      inputEl.focus();
    }
  },
});

declare module "vue/types/vue" {
  interface Vue {
    $axios: any;
  }
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
