import Vue from "vue";
import VueRouter from "vue-router";
import { getLoginStatus } from "@/api/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "../views/login/index.vue"),
  },
  {
    path: "/code-login",
    name: "codeLogin",
    component: () => import(/* webpackChunkName: "code-login" */ "../views/login/code-login.vue"),
  },
  {
    path: "/msp",
    redirect: "/msp/TDhome",
    component: () => import(/* webpackChunkName: "home" */ "../components/layout/index.vue"),
    children: [
      {
        path: "role",
        name: "role",
        component: () => import(/* webpackChunkName: "role" */ "../views/role/index.vue"),
      },
      {
        path: "TDhome",
        name: "TDhome",
        isMenus: true,
        isTD: true,
        title: "首页",
        component: () => import(/* webpackChunkName: "home" */ "../views/TDhome/index.vue"),
      },
      {
        path: "BOhome",
        name: "BOhome",
        redirect: "/msp/BOhome/",
        isMenus: true,
        isTD: false,
        title: "首页",
        component: () => import(/* webpackChunkName: "emptyBox" */ "../views/customer/emptyBox.vue"),
        children: [
          {
            path: "/msp/BOhome/",
            name: "BOhomePage",
            component: () => import(/* webpackChunkName: "BOhome" */ "../views/BOhome/index.vue"),
          },
          {
            path: "BOdevice",
            name: "BOdevice",
            component: () => import(/* webpackChunkName: "BOhome" */ "../views/BOhome/device.vue"),
          },
        ],
      },
      {
        path: "customer",
        name: "customer",
        redirect: "/msp/customer/",
        isMenus: true,
        isTD: true,
        title: "客户",
        component: () => import(/* webpackChunkName: "customer" */ "../views/customer/emptyBox.vue"),
        children: [
          {
            path: "/msp/customer/",
            component: () => import(/* webpackChunkName: "customer" */ "../views/customer/index.vue"),
          },
          {
            path: "site",
            name: "site",
            component: () => import(/* webpackChunkName: "customer" */ "../views/customer/site.vue"),
          },
          {
            path: "TDdevice",
            name: "TDdevice",
            component: () => import(/* webpackChunkName: "customer" */ "../views/customer/device.vue"),
          },
        ],
      },
      {
        path: "company",
        name: "company",
        isMenus: true,
        isTD: true,
        title: "公司",
        component: () => import(/* webpackChunkName: "company" */ "../views/company/index.vue"),
      },
      {
        path: "TDmessage",
        name: "TDmessage",
        isMenus: true,
        isTD: true,
        title: "消息",
        component: () => import(/* webpackChunkName: "message" */ "../views/TDmessage/index.vue"),
      },
      {
        path: "BOmessage",
        name: "BOmessage",
        isMenus: true,
        isTD: false,
        title: "消息",
        component: () => import(/* webpackChunkName: "message" */ "../views/BOmessage/index.vue"),
      },
      {
        path: "construction",
        name: "construction",
        isMenus: true,
        isTD: true,
        title: "施工",
        component: () => import(/* webpackChunkName: "construction" */ "../views/construction/index.vue"),
        redirect: "/msp/construction/rapidConfig",
        children: [
          {
            path: "rapidConfig",
            name: "rapidConfig",
            component: () =>
              import(/* webpackChunkName: "rapidConfig" */ "../views/construction/rapidConfig/index.vue"),
          },
          {
            path: "community",
            name: "community",
            component: () => import(/* webpackChunkName: "community" */ "../views/construction/community.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/404",
    name: "network-not-found",
    component: () => import(/* webpackChunkName: "notfound" */ "../404.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

router.beforeEach(async (to, from, next) => {
  const res = await getLoginStatus();
  if (to.path === "/login" || to.path === "/code-login") {
    next();
  } else {
    if (res.loginStatus) {
      next();
    } else {
      // next("/login");
      // 如果session过期了，但是store中存储的数据不会被重置，就会导致监听不了页面上数据（两边的数据相同），页面渲染会有问题
      location.href = "/";
    }
  }
});

export default router;
